import getClient, { User } from "configcat-js";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { type FeatureFlags } from "@models/feature-flag.model";

// Default values for the feature flags.
const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  PendingTransactions: false,
  primaryPayerDenialsAE: false,
  compass2Enabled: false
};

export function useConfigCat(): [FeatureFlags, boolean] {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(DEFAULT_FEATURE_FLAGS);
  const [isLoaded, setIsLoaded] = useState(false);
  const configcatSdkKey = import.meta.env.VITE_CONFIGCAT_SDK_KEY;

  const { accounts } = useMsal();
  const userId = accounts[0]?.homeAccountId ?? "";
  const email = accounts[0]?.username ?? "";

  const configCatClient = useMemo(() => getClient(configcatSdkKey), [configcatSdkKey]);

  const loadConfigCatValues = useCallback(async (): Promise<void> => {
    const user = new User(userId, email?.toLocaleLowerCase());
    const settingValues = await configCatClient.getAllValuesAsync(user);
    const mappedFlags = settingValues.reduce((obj, cur) => ({ ...obj, [cur.settingKey]: cur.settingValue }), {});
    setFeatureFlags(mappedFlags as FeatureFlags);
    setIsLoaded(true);
  }, [userId, email, configCatClient]);

  useEffect(() => {
    void loadConfigCatValues();

    // Cleanup on unmount.
    return () => {
      configCatClient.dispose();
    };
  }, [userId, email, configCatClient, loadConfigCatValues]);

  return [featureFlags, isLoaded];
}

export default useConfigCat;
