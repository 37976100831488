import { BrowserAuthError, type IPublicClientApplication, InteractionRequiredAuthError,
  type AuthenticationResult, type AccountInfo } from "@azure/msal-browser";
import axios from "axios";
import { graphScopes as scopes } from "@utils/msal.util";

const BASE_API_URL = `${import.meta.env.VITE_USER_MANAGEMENT_API_URL}`;

const axiosInstance = axios.create({
  baseURL: BASE_API_URL
});

type AuthenticationResultAsyncFunction = () => Promise<AuthenticationResult>;

const acquireTokenSilent = async (instance: IPublicClientApplication, account: AccountInfo): Promise<AuthenticationResult> => {
  const authenticationResult = await instance.acquireTokenSilent({
    scopes,
    account
  });
  return authenticationResult;
};

const handleSilentTokenError = async (silentTokenError: Error, fn: AuthenticationResultAsyncFunction):
  Promise<AuthenticationResult> => {
  // Check if the previous request has failed because we need to:
  // Display an authorization window (first login or when the app scopes changes).
  if (silentTokenError instanceof InteractionRequiredAuthError || silentTokenError instanceof BrowserAuthError) {
    const authenticationResult = await fn();
    return authenticationResult;
  }

  throw silentTokenError;
};

const promptLogin = async (instance: IPublicClientApplication): Promise<AuthenticationResult> => {
  const result = await instance.loginPopup({
    scopes,
    prompt: "select_account"
  });
  return result;
};

export const login = async (instance: IPublicClientApplication): Promise<void> => {
  // Display the log in popup window.
  let authenticationResult: AuthenticationResult;

  try {
    authenticationResult = await instance.ssoSilent({ scopes });
  } catch (err) {
    try {
      const account = instance.getAllAccounts()?.[0];
      authenticationResult = await (account ? acquireTokenSilent(instance, account) : promptLogin(instance));
    } catch (silentTokenError) {
      authenticationResult = await handleSilentTokenError(silentTokenError as Error, async () => promptLogin(instance));
    }
  }

  // Fetch and store the tokens.
  if (authenticationResult?.account) {
    try {
      await acquireTokenSilent(instance, authenticationResult.account);
    } catch (silentTokenError) {
      authenticationResult = await handleSilentTokenError(silentTokenError as Error, async () => {
        // Fallback to interaction when silent call fails.
        const result = await instance.acquireTokenPopup({
          scopes
        });
        return result;
      });
    }
  }

  const { idToken, uniqueId, account, accessToken, expiresOn } = authenticationResult;
  const loginData = { accessToken };
  const loginHeaders = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  const userSettings = await axiosInstance.post("/Login", loginData, loginHeaders);
  const { theme, claimBillGridConfig, incidentPin, patientPin, isBatchPrintingResponsable, createdOn } = userSettings.data;

  // Store the Compass API request token and dark theme settings.
  const userId = uniqueId ?? account?.localAccountId ?? "";
  localStorage.setItem("userId", userId);
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("idToken", idToken);
  localStorage.setItem("darkTheme", theme);
  localStorage.setItem("claimBillGridConfig", claimBillGridConfig);
  localStorage.setItem("dashboardPageType", "0");
  localStorage.setItem("incidentPin", incidentPin);
  localStorage.setItem("patientPin", patientPin);
  localStorage.setItem("isBatchPrintingResponsable", isBatchPrintingResponsable);
  localStorage.setItem("createdOn", createdOn);

  const accessTokenExpirationTime = expiresOn?.getTime() ?? new Date().getTime();
  localStorage.setItem("accessTokenExpirationTime", accessTokenExpirationTime.toString());

  // Set Compass API request token in the axios instance.
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default login;
