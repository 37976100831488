import { useTheme } from "@mui/material/styles";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Box, Paper, styled, Typography } from "@mui/material";
import { AnimatePresence, motion, type AnimationProps } from "framer-motion";
import dayjs from "dayjs";
import { type LeftPanelCardDetailProps } from "@models/left-panel-card-detail-props.model";
import { type ViewBill } from "@models/view-bill.model";
import { getServiceLineIcon } from "@logic/global-search.logic";

const CardDetail = styled(Paper)<AnimationProps & { selected: boolean, workedOn: boolean }>(({ theme, selected, workedOn }) => ({
  borderStyle: "solid",
  borderWidth: selected ? "0 0 0.25rem 0.25rem" : "0",
  margin: "0 2rem 1rem 1rem",
  borderRadius: selected ? "0.5rem 0 0 0.5rem" : "0.5rem",
  cursor: "pointer",
  backgroundColor: theme.palette.secondary.dark,
  borderColor: workedOn ? theme.palette.secondary.light : theme.palette.secondary.dark,
  direction: "ltr",
  ".workedToday *": {
    backdropFilter: "grayscale(60%)"
  }
}));

const CardDetailHeader = styled(Box)<AnimationProps & { selected: boolean }>(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.secondary.light : theme.palette.secondary.dark,
  paddingLeft: "1rem",
  fontSize: "0.7rem",
  borderRadius: "0.25rem 0.25rem 0 0"
}));

const CardDetailFooter = styled("div")(() => ({
  padding: "0.5rem 0.75rem 0.5rem 0.75rem",
  borderRadius: "0.25rem",
  display: "flex",
  alignItems: "center"
}));

const DateBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "1.625rem",
  height: "1.625rem",
  borderRadius: "1rem",
  color: theme.palette.info.dark
}));

const ChipBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
  borderRadius: "0.375rem",
  padding: "0 0.5rem",
  margin: "0.25rem 0"
}));

const StatusBox = styled("div")(() => ({
  borderRadius: "0.375rem",
  padding: "0 0.5rem",
  margin: "0.25rem 0",
  width: "fit-content"
}));

const LeftPanelCardDetail = ({ index, id, item, selected, onHandleSelect, workedOn }: LeftPanelCardDetailProps): JSX.Element => {
  const muiTheme = useTheme();
  const variants = {
    initial: {
      opacity: 0
    },
    open: {
      marginRight: 0,
      opacity: 1,
      backgroundColor: muiTheme.palette.secondary.dark
    },
    closed: {
      opacity: 1,
      backgroundColor: muiTheme.palette.secondary.light
    }
  };

  return (
    <AnimatePresence>
      <CardDetail
        data-testid="left-panel-card-detail"
        initial="initial"
        component={motion.div}
        variants={variants}
        animate={id === selected ? "open" : "closed"}
        transition={{ ease: "easeInOut", duration: 0.3, delay: index * 0.001 }}
        onClick={() => onHandleSelect(id)}
        selected={id === selected}
        workedOn={workedOn}
        className="workedToday"
      >
        {workedOn && (
          <CardDetailHeader
            component={motion.div}
            initial={{ x: "100%", opacity: 0, scale: 1 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            selected={id === selected}
          >
            Analysts are working
          </CardDetailHeader>
        )}
        <Box sx={{
          paddingRight: `${id === selected ? "2.125rem" : "0"}`,
          backdropFilter: item?.isWorkedToday ? "sepia(30%)" : "none"
        }}
        >
          <Box sx={{ padding: `${workedOn ? "0 0.75rem 0.75rem 0.75rem" : "0.75rem"}` }}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              sx={{ paddingTop: `${workedOn ? "0.75rem" : "0"}` }}
            >
              <Box alignItems="center" display="flex">
                <img
                  src={getServiceLineIcon(item.serviceLine ?? "")}
                  alt={item.serviceLine ?? "service line icon"}
                  style={{ width: "2rem", height: "2rem", filter: "invert(1)" }}
                />
                <Box marginLeft={1}>
                  <Typography data-dd-privacy="mask" variant="body2">
                    {item.dbnum}
                  </Typography>
                  <Typography data-dd-privacy="mask" variant="caption">
                    {item.patientFirstName} {item.patientLastName}
                  </Typography>
                </Box>
              </Box>
              {item.sinceWorked && (
                <Box>
                  <DateBox sx={{
                    backgroundColor: item?.isWorkedToday ? muiTheme.palette.info.light : muiTheme.palette.grey[400],
                    flexDirection: "column"
                  }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ lineHeight: 0.95, fontSize: "0.625rem" }}
                      textAlign="center"
                    >
                      <strong>{dayjs().diff(dayjs(item.sinceWorked), "day")}</strong>{" "}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ lineHeight: 0.95, fontSize: "0.5rem" }}
                    >
                      DSW
                    </Typography>
                  </DateBox>
                </Box>
              )}
            </Box>
            <Box
              alignItems={(item.followUp && item.timely) ? "center" : ""}
              display={(item.followUp && item.timely) ? "flex" : ""}
              justifyContent={(item.followUp && item.timely) ? "space-between" : ""}
            >
              {item.followUp
                && (
                  <ChipBox>
                    <Typography variant="caption">
                      Follow up {item.followUp}
                    </Typography>
                  </ChipBox>
                )}
              {item.timely
                && (
                  <ChipBox>
                    <Typography variant="caption">
                      Timely {dayjs(item.timely).format("MM/DD/YYYY")}
                    </Typography>
                  </ChipBox>
                )}
            </Box>
            <Box>
              <ChipBox>
                <Typography variant="caption" data-dd-privacy="mask">
                  {item.facility}
                </Typography>
              </ChipBox>
            </Box>
            {item?.bills?.map((x: ViewBill, i: number) => (
              <Box key={i}>
                <Typography variant="caption" data-dd-privacy="mask">
                  {x.billNumber} {x.phase}
                </Typography>
                <StatusBox sx={{ backgroundColor: item?.isWorkedToday ? muiTheme.palette.info.light : muiTheme.palette.grey[400] }}>
                  <Typography variant="caption" color="secondary.contrastText">
                    {x.status}
                  </Typography>
                </StatusBox>
              </Box>
            ))}
          </Box>
          {item?.linkedAccounts?.map(it => (
            <CardDetailFooter key={it.incidentId}>
              <InsertLinkIcon />
              <Typography data-dd-privacy="mask" variant="body2" sx={{ margin: "0 0.5rem" }}>
                {it.dbnum}
              </Typography>
              <Typography data-dd-privacy="mask" variant="caption" sx={{ margin: "0 0.5rem" }}>
                {it.patientFirstName} {it.patientLastName}
              </Typography>
            </CardDetailFooter>
          ))}
        </Box>
      </CardDetail>
    </AnimatePresence>
  );
};

export default LeftPanelCardDetail;
