import type { Field } from "react-querybuilder";
import alphabetDropdownOptions from "@data/alphabet-letters.data";
import fields from "@data/field-names-type.data";

// `greater`, `less`, `equal` operators
export const greaterLessEqOperators = [
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "=", label: "=" }
];

// `greater`, `less`, `equal`, `between` operators
const greaterLessEqBetweenOperators = [
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "=", label: "=" },
  { name: "between", label: "between" }
];

const pastFutureGreaterLessEqOperators = [
  { name: "pastRange", label: "past" },
  { name: "futureRange", label: "future" },
  { name: "<", label: "<" },
  { name: ">", label: ">" },
  { name: "=", label: "=" }
];

// `equal`, `not_equal` operators
const isNotOperators = [
  { name: "in", label: "Is" },
  { name: "notIn", label: "is not" }
];

// `beginsWith`, `endsWith` operators
const startsOrEndsWithOperator = [
  { name: "beginsWith", label: "starts with" },
  { name: "endsWith", label: "ends with" }
];

// `Is` operator
const isOperator = [{ name: "=", label: "Is" }];

// 'Between' and `BeginsWith` operator
const betweenAndBeginsWithOperator = [
  { name: "between", label: "between" },
  { name: "beginsWith", label: "starts with" }
];

// `is`, `at least`, `at most` operator
const isAtLeastAtMostOperator = [
  { name: "is", label: "is" },
  { name: "atLeast", label: "at least" },
  { name: "atMost", label: "at most" }
];

interface ExtendedField extends Field {
  notPaginated?: boolean;
}

const currentDate = new Date();

const defaultQueryBuilderFields: ExtendedField[] = [
  {
    name: fields.serviceLine.name,
    label: "Service Line",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.activeAge.name,
    label: "Active Age",
    placeholder: "Enter Active Age...",
    inputType: "number",
    valueEditorType: "text",
    operators: greaterLessEqOperators
  },
  {
    name: fields.assignedTo.name,
    label: "Assigned To",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.admissionDate.name,
    label: "Admission Date",
    inputType: "date",
    datatype: "date",
    operators: greaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.balance.name,
    label: "Balance",
    placeholder: "Enter Balance...",
    inputType: "number",
    valueEditorType: "text",
    operators: greaterLessEqOperators
  },
  {
    name: fields.phase.name,
    label: "Phase",
    notPaginated: true,
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.status.name,
    label: "Status",
    operators: isNotOperators,
    datatype: "nestedSelect",
    notPaginated: true
  },
  {
    name: fields.billType.name,
    label: "Bill Type",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.carc.name,
    label: "CARC",
    operators: isOperator,
    inputType: "text",
    valueEditorType: "text"
  },
  {
    name: fields.rarc.name,
    label: "RARC",
    operators: isOperator,
    inputType: "text",
    valueEditorType: "text"
  },
  {
    name: fields.coder.name,
    label: "Coder",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.contract.name,
    label: "Contract",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.courtDate.name,
    label: "Court Date",
    inputType: "date",
    datatype: "date",
    operators: greaterLessEqOperators
  },
  {
    name: fields.createdDate.name,
    label: "Created Date",
    inputType: "date",
    datatype: "date",
    operators: pastFutureGreaterLessEqOperators
  },
  {
    name: fields.placedDate.name,
    label: "Placed Date",
    inputType: "date",
    datatype: "date",
    operators: pastFutureGreaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.dob.name,
    label: "DOB (Date of Birth)",
    inputType: "date",
    datatype: "date",
    operators: greaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.dis.name,
    label: "DIS (Days in Status)",
    inputType: "number",
    placeholder: "Enter Days in Status...",
    operators: greaterLessEqOperators
  },
  {
    name: fields.dsw.name,
    label: "DSW (Days Since Worked)",
    inputType: "number",
    placeholder: "Enter Days Since Worked...",
    operators: greaterLessEqOperators
  },
  {
    name: fields.deadlineType.name,
    label: "Deadline Types",
    inputType: "text",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.denialCategory.name,
    label: "Denial Category",
    inputType: "text",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.denialType.name,
    label: "Denial Types",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.dischargeDate.name,
    label: "Discharge Date",
    inputType: "date",
    datatype: "date",
    operators: pastFutureGreaterLessEqOperators
  },
  {
    name: fields.facility.name,
    label: "Facility",
    inputType: "text",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.facilityState.name,
    label: "Facility State",
    inputType: "text",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.firstName.name,
    label: "First Name",
    valueEditorType: "select",
    operators: startsOrEndsWithOperator,
    values: alphabetDropdownOptions
  },
  {
    name: fields.lastName.name,
    label: "Last Name",
    valueEditorType: "select",
    operators: startsOrEndsWithOperator,
    values: alphabetDropdownOptions
  },
  {
    name: fields.lastInitial.name,
    label: "Last Initial",
    valueEditorType: "select",
    operators: betweenAndBeginsWithOperator,
    values: alphabetDropdownOptions
  },
  {
    name: fields.followUpDate.name,
    label: "Followup Date",
    inputType: "date",
    datatype: "date",
    operators: pastFutureGreaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.healthDueDate.name,
    label: "Health Due Date",
    inputType: "date",
    datatype: "date",
    operators: greaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.interpleaderIndicator.name,
    label: "Interpleader Indicator",
    inputType: "text",
    operators: isOperator,
    valueEditorType: "checkbox"
  },
  {
    name: fields.lienDueDate.name,
    label: "Lien Due Date",
    inputType: "date",
    datatype: "date",
    operators: greaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.lienFollowUp.name,
    label: "Lien Follow up",
    inputType: "date",
    datatype: "date",
    operators: greaterLessEqOperators,
    defaultValue: currentDate
  },
  {
    name: fields.medicaidState.name,
    label: "Medicaid States",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.nurse.name,
    label: "Nurse",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.participant.name,
    label: "Participant",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.paymentApprover.name,
    label: "Payment Approver",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.payorType.name,
    label: "Payor type",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.primaryInsurances.name,
    label: "Primary Insurances",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.primaryInsuranceState.name,
    label: "Primary Insurance state",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.programType.name,
    label: "Program Type",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "select",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.reconsiderationReasons.name,
    label: "Reconsideration Reasons",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.secondaryInsurances.name,
    label: "Secondary Insurances",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.tags.name,
    label: "Tags",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.tertiaryInsurances.name,
    label: "Tertiary Insurances",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.totalCharge.name,
    label: "Total Charge",
    placeholder: "Enter Total Charge...",
    inputType: "number",
    valueEditorType: "text",
    operators: greaterLessEqOperators
  },
  {
    name: fields.treatmentTypes.name,
    label: "Treatment Types",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect"
  },
  {
    name: fields.upcomingBillDeadlineDate.name,
    label: "Upcoming Bill Deadline Date",
    inputType: "date",
    datatype: "date",
    operators: pastFutureGreaterLessEqOperators
  },
  {
    name: fields.upcomingBillDeadlineType.name,
    label: "Upcoming Bill Deadline Type",
    inputType: "select",
    operators: isNotOperators,
    valueEditorType: "multiselect",
    datatype: "paginatedSelect",
    notPaginated: true
  },
  {
    name: fields.paymentLikelihood.name,
    label: "Payment Likelihood (%)",
    placeholder: "Enter Payment Likelihood...",
    inputType: "number",
    valueEditorType: "text",
    operators: greaterLessEqBetweenOperators
  },
  {
    name: fields.lengthOfStay.name,
    label: "Length of stay (days)",
    placeholder: "Enter length of stay...",
    inputType: "number",
    valueEditorType: "text",
    operators: isAtLeastAtMostOperator
  }
];

const getQueryBuilderFields = (pendingTransactionsFeatureFlag: boolean): ExtendedField[] => {
  const queryBuilderFields = structuredClone(defaultQueryBuilderFields);
  if (pendingTransactionsFeatureFlag) {
    queryBuilderFields.push({
      name: fields.approvalStatus.name,
      label: "Approval Status",
      inputType: "select",
      operators: isNotOperators,
      valueEditorType: "multiselect",
      datatype: "paginatedSelect",
      notPaginated: true
    });
  }

  return queryBuilderFields;
};

export default getQueryBuilderFields;
