import type React from "react";
import { useMemo, useState } from "react";
import {
  QueryBuilder, findPath, getParentPath, useRuleGroup, TestID,
  RuleGroupBodyComponents, RuleGroupHeaderComponents, type ValidationResult,
  type ActionWithRulesProps, type RuleGroupProps, type CombinatorSelectorProps,
  type RuleGroupType, type FieldSelectorProps, type FullOptionList, type FlexibleOption,
  type OperatorSelectorProps
} from "react-querybuilder";
import { QueryBuilderMaterial, MaterialValueSelector, type RQBMaterialComponents } from "@react-querybuilder/material";
import { memo, type MouseEvent } from "react";
import DragIndicator from "@mui/icons-material/DragIndicator";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import clsx from "clsx";
import Switch from "@mui/material/Switch";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { type Theme, styled, Typography, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { queryBuilderValidator } from "@logic/query-builder.logic";
import CombinatorTypes from "@enums/combinator-type.enum";
import { type QueryBuilderProps } from "@models/query-builder-props.model";
import { useConfigCat } from "@hooks/use-configcat.hook";
import getQueryBuilderFields from "./fields";
import CustomValueEditor from "./custom-value-editor.component";

const combinatorsOptions: FullOptionList<FlexibleOption> = [
  { label: "All of the following are true", name: CombinatorTypes.And, value: CombinatorTypes.And },
  { label: "Any of the following are true", name: CombinatorTypes.Or, value: CombinatorTypes.Or }
];

const defaultOptions: FullOptionList<FlexibleOption> = [
  { value: "~", label: "Select...", name: "~" }
];

interface QueryBuilderContextType {
  query: RuleGroupType;
  isComplexView: boolean;
}

const getStyles = (theme: Theme): object => ({
  borderBottom: `1px solid ${theme.palette.grey[600]}`
});

const MuiSelect = styled(Select)(({ theme }) => ({
  ...getStyles(theme)
})) as unknown as typeof Select;

const MuiInput = styled(Input)(({ theme }) => ({
  ...getStyles(theme),
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none"
  },
  "& input[type=number]": {
    MozAppearance: "textfield"
  }
})) as unknown as typeof Input;

const muiComponents = {
  Button,
  Checkbox,
  DragIndicator,
  FormControl,
  FormControlLabel,
  Input: MuiInput,
  ListSubheader,
  MenuItem,
  Radio,
  RadioGroup,
  Select: MuiSelect,
  Switch,
  TextareaAutosize
} as RQBMaterialComponents;

const composeErrorMessageAndStyle = (
  validation: boolean | ValidationResult | undefined,
  value: string | undefined,
  addMargin = false
): JSX.Element => {
  const { valid } = validation as { valid: boolean, reasons: string[] };

  if (valid) {
    return <div />;
  }

  if (!valid && value === "~") {
    if (addMargin) {
      return <div style={{ marginBottom: "1.5rem" }} />;
    }

    return <div style={{ color: "red" }}>Rule type is required</div>;
  }

  if (!valid && value !== "~") {
    return <div style={{ marginBottom: "1.5rem" }} />;
  }

  return <div />;
};

const RenderRemoveBtn = ({ handleOnClick }: ActionWithRulesProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="clear"
        id="delete-filter-button"
        onClick={handleClick}
        sx={{ marginLeft: "auto" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="delete-filter"
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnClick}>
          <Typography variant="body2" color={theme => theme.palette.error.main}>Delete this filter</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const RenderRemoveGroupBtn = ({ handleOnClick }: ActionWithRulesProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="clear"
        id="delete-filter-button"
        onClick={handleClick}
        sx={{ marginLeft: "auto" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="delete-filter"
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOnClick}>
          <Typography variant="body2" color={theme => theme.palette.error.main}>Delete this group</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const RenderCombinatorSelect = (props: CombinatorSelectorProps): JSX.Element | null => {
  const { path, context, level } = props;

  if (context.isComplexView) {
    const parentPath = getParentPath(path);
    const parentGroup = findPath(parentPath, context.query) as RuleGroupType;
    const prefix = level === 0 ? "" : parentGroup.combinator;
    const combinatorPrefix = prefix.charAt(0).toUpperCase().concat(prefix.slice(1));

    return (
      <>
        <span style={{ marginLeft: level > 0 ? "0.4rem" : "" }}>{combinatorPrefix}</span>
        <MaterialValueSelector {...props} options={combinatorsOptions} />
      </>
    );
  }

  return null;
};

const RenderFieldSelector = (props: FieldSelectorProps): JSX.Element => {
  const { path, context, level, value, className, validation } = props;
  const parentPath = getParentPath(path);
  const parentGroup = findPath(parentPath, context.query) as RuleGroupType;

  const fieldSelectorIndexInGroup = path[level - 1];
  const prefix = fieldSelectorIndexInGroup === 0 ? "" : parentGroup.combinator;
  const marginLeft = parentGroup.combinator === CombinatorTypes.And ? "2.8rem" : "2.1rem";
  const combinatorPrefix = prefix.toUpperCase();

  const errorClassName = value === "~" ? "error" : "";

  return (
    <>
      <span style={{ marginLeft: fieldSelectorIndexInGroup === 0 ? marginLeft : "1rem" }}>{combinatorPrefix}</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <MaterialValueSelector
          {...props}
          sx={{ width: value === "~" ? "10rem" : null }}
          className={clsx(className, errorClassName)}
        />
        {validation != null && composeErrorMessageAndStyle(validation, value)}
      </div>
      {
        value === "~" && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MaterialValueSelector
                {...props}
                sx={{ width: "10rem" }}
                options={defaultOptions}
                disabled
                className={clsx(className, errorClassName)}
              />
              {validation != null && composeErrorMessageAndStyle(validation, value, true)}
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <MaterialValueSelector
                {...props}
                sx={{ width: "10rem" }}
                options={defaultOptions}
                disabled
                className={clsx(className, errorClassName)}
              />
              {validation != null && composeErrorMessageAndStyle(validation, value, true)}
            </div>
          </>
        )
      }
    </>
  );
};

const RenderCustomRuleGroup = (props: RuleGroupProps): JSX.Element | null => {
  const rg = { ...props, ...useRuleGroup(props) };
  const { path } = props;
  const [addRule, addGroup] = [rg.addRule, rg.addGroup]
    .map(f => (event: MouseEvent, cntx?: QueryBuilderContextType) => {
      event.preventDefault();
      event.stopPropagation();
      f(event, cntx);
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subComponentProps: any = { ...rg, addRule, addGroup };

  if (path.length < 3) {
    return (
      <div
        ref={rg.previewRef}
        className={rg.outerClassName}
        data-testid={TestID.ruleGroup}
        data-dragmonitorid={rg.dragMonitorId}
        data-dropmonitorid={rg.dropMonitorId}
        data-rule-group-id={rg.id}
        data-level={rg.path.length}
        data-path={JSON.stringify(rg.path)}
      >
        <div ref={rg.dropRef} className={rg.classNames.header}>
          <RuleGroupHeaderComponents
            {...subComponentProps}
            schema={{
              ...subComponentProps.schema,
              controls: {
                ...subComponentProps.schema.controls,
                addRuleAction: () => null,
                addGroupAction: () => null
              }
            }}
          />
        </div>
        <div className={rg.classNames.body}>
          <RuleGroupBodyComponents {...subComponentProps} />
        </div>
        <div>
          <Button
            variant="text"
            size="small"
            color="inherit"
            onClick={e => rg.addGroup(e)}
            sx={{ marginRight: "1rem" }}
            disabled={path.length === 2 || props?.parentDisabled}
          >
            Add Group
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={e => rg.addRule(e)}
            disabled={props?.parentDisabled}
          >
            Add Filter
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

const RenderOperatorSelect = (props: OperatorSelectorProps): JSX.Element => {
  const { validation } = props;
  const valid: boolean = validation != null ? (validation as ValidationResult).valid : true;

  return <MaterialValueSelector style={{ marginBottom: !valid ? "1.5rem" : 0 }} {...props} />;
};

const ReactQueryBuilder = ({ query, onQueryChange, config, showErrors, disabled }: QueryBuilderProps): JSX.Element => {
  const [{ PendingTransactions }, featureFlagsLoaded] = useConfigCat();
  const fields = useMemo(() => {
    if (!featureFlagsLoaded) {
      return [];
    }

    return getQueryBuilderFields(PendingTransactions);
  }, [PendingTransactions, featureFlagsLoaded]);

  const isComplexView = Boolean(config?.isComplexView);
  const queryBuilderContext: QueryBuilderContextType = { query, isComplexView };

  const handleOnAddGroup = (ruleGroup: RuleGroupType, parentPath: number[], localQuery: RuleGroupType): RuleGroupType | false => {
    const parentGroup = findPath(parentPath, localQuery) as RuleGroupType;
    const ruleGroupCombinator = parentGroup.combinator === CombinatorTypes.And
      ? ruleGroup.combinator = CombinatorTypes.Or
      : ruleGroup.combinator = CombinatorTypes.And;

    return { ...ruleGroup, combinator: ruleGroupCombinator };
  };

  const rearrangeQueryOnChange = (localQuery: RuleGroupType): void => {
    const rearranged = rearrangeQuery(localQuery);
    query = rearranged;
    onQueryChange(rearranged);
  };
  const rearrangeQuery = (localQuery: RuleGroupType): RuleGroupType => {
    const filters = localQuery.rules.filter(rule => !("rules" in rule));
    const groups = localQuery.rules.filter((rule): rule is RuleGroupType => "rules" in rule);
    const rearrangedGroups = groups.map(group => ({
      ...group,
      rules: rearrangeQuery(group).rules
    }));

    return {
      ...localQuery,
      rules: [...filters, ...rearrangedGroups]
    };
  };

  return (
    <QueryBuilderMaterial muiComponents={muiComponents}>
      <QueryBuilder
        disabled={disabled}
        fields={fields}
        query={query}
        onQueryChange={rearrangeQueryOnChange}
        controlElements={{
          valueEditor: CustomValueEditor,
          removeRuleAction: disabled ? () => null : RenderRemoveBtn,
          removeGroupAction: disabled ? () => null : RenderRemoveGroupBtn,
          combinatorSelector: RenderCombinatorSelect,
          fieldSelector: RenderFieldSelector,
          ruleGroup: RenderCustomRuleGroup,
          operatorSelector: RenderOperatorSelect
        }}
        addRuleToNewGroups
        enableMountQueryChange={false}
        autoSelectField={false}
        onAddGroup={handleOnAddGroup}
        validator={showErrors ? queryBuilderValidator : undefined}
        context={queryBuilderContext}
        translations={{
          fields: { placeholderLabel: "Select..." }
        }}
      />
    </QueryBuilderMaterial>
  );
};

export default memo(ReactQueryBuilder);
