import { Grid, Box, Paper, styled, Typography, Collapse } from "@mui/material";
import { motion } from "framer-motion";
import dayjs from "dayjs";
import { getServiceLineIcon } from "@logic/global-search.logic";
import { type ViewSearchResult } from "@models/view-search-result.model";

const CardDetail = styled(Paper)(({ theme }) => ({
  borderStyle: "solid",
  borderWidth: "0",
  margin: "1rem 0 0 1rem",
  borderRadius: "0.5rem 0 0 0.5rem",
  cursor: "pointer",
  backgroundColor: theme.palette.secondary.dark,
  pointerEvents: "none"
}));

const DateBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.info.light,
  width: "2.125rem",
  height: "2.125rem",
  borderRadius: "2rem",
  color: theme.palette.info.dark
}));

const ListHeader = ({ selectedItem, show }: { selectedItem: ViewSearchResult | null, show: boolean }): JSX.Element => (
  <Grid
    container
    spacing={0}
    direction="column"
  >
    <Collapse in={show}>
      <CardDetail
        component={motion.div}
      >
        <Box sx={{ padding: "0.75rem" }}>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Box alignItems="center" display="flex">
              <img
                src={getServiceLineIcon(selectedItem?.serviceLine ?? "")}
                alt={selectedItem?.serviceLine ?? "service line icon"}
                style={{ width: "2rem", height: "2rem", filter: "invert(1)" }}
              />
              <Box marginLeft={1}>
                <Typography data-dd-privacy="mask" variant="body2">
                  {selectedItem?.dbnum}
                </Typography>
                <Typography data-dd-privacy="mask" variant="caption">
                  {selectedItem?.patientFirstName} {selectedItem?.patientLastName}
                </Typography>
              </Box>
            </Box>
            <Box>
              {selectedItem?.sinceWorked && selectedItem?.sinceWorked !== "0001-01-01T00:00:00"
              && selectedItem?.sinceWorked !== "0001-01-01T00:00:00+00:00"
              && (
              <DateBox>
                <Typography variant="body2" sx={{ lineHeight: 0.95 }} textAlign="center">
                  <strong>{dayjs().diff(dayjs(selectedItem?.sinceWorked), "day")}</strong>{" "}
                  <Typography variant="caption" sx={{ lineHeight: 0.95 }}>
                    DSW
                  </Typography>
                </Typography>
              </DateBox>
              )}
            </Box>
          </Box>
        </Box>
      </CardDetail>
    </Collapse>
  </Grid>
);

export default ListHeader;
