import { type SortOptionsType } from "@models/sort-options-type.model";
import SortType from "@enums/sort-type.enum";

const sortOptions: SortOptionsType = {
  admissionDate: {
    label: "Admission date by",
    name: SortType.AdmissionDate,
    ascOption: "oldest",
    descOption: "newest"
  },
  assignedTo: {
    label: "Assigned",
    name: SortType.AssignedTo,
    ascOption: "A-Z",
    descOption: "Z–A"
  },
  balance: {
    label: "Balance",
    name: SortType.Balance,
    ascOption: "low to high",
    descOption: "high to low"
  },
  dsw: {
    label: "Days since worked by",
    name: SortType.Dsw,
    ascOption: "oldest",
    descOption: "newest"
  },
  followupDate: {
    label: "Follow-up date by",
    name: SortType.FollowupDate,
    ascOption: "oldest",
    descOption: "newest"
  },
  timelyFiling: {
    label: "Timely filing by",
    name: SortType.TimelyFiling,
    ascOption: "oldest",
    descOption: "newest"
  },
  totalCharge: {
    label: "Total charge",
    name: SortType.TotalCharge,
    ascOption: "low to high",
    descOption: "high to low"
  },
  dis: {
    label: "Days in status by",
    name: SortType.Dis,
    ascOption: "oldest",
    descOption: "newest"
  },
  primaryHealthPolicyNumber: {
    label: "Primary health policy number",
    name: SortType.PrimaryHealthPolicyNumber,
    ascOption: "A-Z",
    descOption: "Z–A"
  },
  primaryPayer: {
    label: "Primary payer",
    name: SortType.PrimaryPayer,
    ascOption: "A-Z",
    descOption: "Z–A"
  },
  upcomingBillDeadlineDate: {
    label: "Upcoming bill deadline by",
    name: SortType.UpcomingBillDeadlineDate,
    ascOption: "oldest",
    descOption: "newest"
  }
};

export default sortOptions;
