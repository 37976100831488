import { Box, InputAdornment, TextField, Typography, alpha, useTheme } from "@mui/material";
import Autocomplete, { type AutocompleteRenderInputParams, type AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { useEffect, useRef, useState, useCallback, type KeyboardEvent as ReactKeyboardEvent, type SyntheticEvent } from "react";
import { useDebounceValue } from "usehooks-ts";
import useGlobalSearchStore from "@store/global-search.store";
import { getGlobalSearch } from "@services/global-search.service";
import { type GlobalSearchItem as GlobalSearchItemModel } from "@models/global-search-item.model";
import { getHighlightSearchText, getHighlightText, getNormalizedHighlights } from "@logic/global-search.logic";
import SearchIcon from "@mui/icons-material/Search";
import AccountDialog from "@components/account-dialog/account-dialog.component";
import useHubConnection from "@hooks/use-hub-connection.hook";
import Alert from "@components/alert/alert.component";
import { type AlertButton } from "@models/alert-button.model";
import { HubConnectionState } from "@microsoft/signalr";
import useSessionStore from "@store/session.store";
import GlobalSearchItem from "./global-search-item/global-search-item.component";

const ACCOUNTS_HUB_URL = `${import.meta.env.VITE_WORK_QUEUE_API_URL}/notifications`;

const GlobalSearchDropdown = (): JSX.Element => {
  const theme = useTheme();
  const { globalSearch, setCurrentAccount, load, currentAccount } = useGlobalSearchStore();

  const [inputValue, setInputValue] = useState<string>("");
  const [searchSuggestion, setSearchSuggestion] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [showDoubleWorkAlert, setShowDoubleWorkAlert] = useState<boolean>(false);
  const [doubleWorkAlertShown, setDoubleWorkAlertShown] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");

  const workedAccountsHub = useHubConnection(ACCOUNTS_HUB_URL);

  const debouncedInputValue = useDebounceValue<string>(inputValue, 300);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [analystsWorkingAccount, setAnalystsWorkingAccount] = useState<string[] | null>(null);

  const { session: { user } } = useSessionStore();

  const goToAccount = (option: GlobalSearchItemModel): void => {
    cleanupState();
    setCurrentAccount(option);
    setShowDialog(true);
    setSelected(option.data.incidentId);
  };

  // Reset current account on page unload.
  useEffect(() => () => setCurrentAccount(null), [setCurrentAccount]);

  useEffect(() => {
    // Request the analysts working the account after the connection started with success.
    if (workedAccountsHub?.state === HubConnectionState.Connected && user?.id
      && selected && showDialog) {
      void workedAccountsHub?.send("RequestAnalystsWorkingBucketAccount", selected, user?.id);
    }
  }, [selected, workedAccountsHub, user, showDialog]);

  const cleanupState = (): void => {
    setAnalystsWorkingAccount(null);
    setShowDoubleWorkAlert(false);
    setDoubleWorkAlertShown(false);
  };

  workedAccountsHub?.onreconnected(() => {
    if (user?.id && selected) {
      cleanupState();

      // Request the analysts working the account after the connection re-started with success.
      void workedAccountsHub?.send("RequestAnalystsWorkingBucketAccount", selected, user?.id);
    }
  });

  workedAccountsHub?.off("AnalystStartsWorkingBucketAccount");
  workedAccountsHub?.on("AnalystStartsWorkingBucketAccount", (incidentId, userId) => {
    // New analyst starting to work the same account.
    if (incidentId === selected && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        const existingAnalystId = prevState?.find(id => id === userId);
        if (!existingAnalystId) {
          if (prevState) {
            return [...prevState, userId];
          }

          return [userId];
        }

        return prevState;
      });
    }
  });

  workedAccountsHub?.off("AnalystStoppedWorkingBucketAccount");
  workedAccountsHub?.on("AnalystStoppedWorkingBucketAccount", (incidentId, userId) => {
    // Analyst stopped to work the same account.
    if (incidentId === selected && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        if (prevState) {
          const existingAnalystId = prevState?.find(id => id === userId);
          if (existingAnalystId) {
            return prevState?.filter(id => id !== userId);
          }
        }

        return prevState;
      });
    }
  });

  workedAccountsHub?.off("SetAnalystAccountWorkCompletedForTheDay");
  workedAccountsHub?.on("SetAnalystAccountWorkCompletedForTheDay", (incidentId, userId) => {
    // Another analyst already worked on that account
    if (incidentId === selected && userId !== user?.id) {
      setAnalystsWorkingAccount(prevState => {
        const existingAnalyst = prevState?.find(id => id === userId);
        // Update message, from analyst is viewing to analyst worked the account
        if (existingAnalyst) {
          return prevState;
        }

        if (prevState) {
          return [...prevState, userId];
        }

        return [userId];
      });
    }
  });

  workedAccountsHub?.off("UpdateAnalystsWorkingTheSameBucketAccount");
  workedAccountsHub?.on("UpdateAnalystsWorkingTheSameBucketAccount", (incidentId, userId) => {
    // Initial setup - get and update all analysts that are already working the same account.
    if (incidentId === selected && userId !== user?.id) {
      if (!doubleWorkAlertShown) {
        setShowDoubleWorkAlert(true);
        setDoubleWorkAlertShown(true);
      }

      setAnalystsWorkingAccount(prevState => {
        const existingAnalyst = prevState?.find(id => id === userId);
        if (!existingAnalyst) {
          if (prevState) {
            return [...prevState, userId];
          }

          return [userId];
        }

        return prevState;
      });
    }
  });

  useEffect(() => {
    const search = async (text: string): Promise<void> => {
      if (!text) {
        load?.([]);
        setCurrentAccount(null);
        return;
      }

      const highlightedSearchSuggestion = await getHighlightSearchText(text);
      setSearchSuggestion(highlightedSearchSuggestion);

      const searchResults = await getGlobalSearch(text, 5);
      if (!searchResults) {
        load?.([]);
        setCurrentAccount(null);
        return;
      }

      load?.(searchResults);
    };
    void search(debouncedInputValue[0]);
  }, [debouncedInputValue[0], load, setCurrentAccount]);

  useEffect(() => (() => {
    void workedAccountsHub?.stop();
  }), [workedAccountsHub]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = useCallback((event: ReactKeyboardEvent<HTMLDivElement> | KeyboardEvent): void => {
    if (event.key === "Tab" && searchSuggestion) {
      event.preventDefault();
      const newInputValue = searchSuggestion;
      setInputValue(newInputValue);
      setSearchSuggestion("");
    }

    if (event.key === "Home") {
      const input = document.getElementById("global-search-autocomplete");
      if (input) {
        (input as HTMLInputElement).setSelectionRange(0, 0);
      }
    }

    if (event.key === "End") {
      const input = document.getElementById("global-search-autocomplete");
      if (input) {
        const item = (input as HTMLInputElement);
        item.setSelectionRange(inputValue.length, inputValue.length);
      }
    }
  }, [searchSuggestion]);

  useEffect(() => {
    const tempInputRef = inputRef.current;
    if (tempInputRef) {
      tempInputRef.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (tempInputRef) {
        tempInputRef.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [inputRef, handleKeyDown]);

  const focusOnSearch = (): void => {
    setOpen(true);
    document.querySelector(".global-search-hint")?.classList.add("show");
  };

  const handleInputChange = async (
    event: KeyboardEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ): Promise<void> => {
    if (reason !== "input") {
      return;
    }

    setInputValue(value);
    // We clear the current suggestion when the user starts/keeps typing
    // and we retrieve a new suggestion in the useEffect hook for the debounced
    // input value. This way the user won't be shown irrelevant suggestions that
    // overlap the search text providing no value at all.
    setSearchSuggestion("");
  };

  const onHandleInputChange = (
    event: SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ): void => {
    void handleInputChange(event?.nativeEvent as KeyboardEvent, value, reason);
  };

  const checkIfOptionIncludesInput = (option: GlobalSearchItemModel): boolean => {
    let value = false;
    const normalizedHighlights = getNormalizedHighlights(option);

    for (const highlight in normalizedHighlights) {
      if (normalizedHighlights[highlight]?.some((el: string) => getHighlightText(inputValue, el) !== "")) {
        value = true;
        break;
      }
    }

    return value;
  };

  const renderOption = (_props: unknown, option: GlobalSearchItemModel): JSX.Element | null => {
    if (!open || !inputValue || !checkIfOptionIncludesInput(option)) {
      return null;
    }

    return (
      <li
        className="global-search-item"
        data-dd-privacy="mask"
        key={`${option.data.incidentId} - ${option.data.billId ? option.data.billId : ""}`}
      >
        <GlobalSearchItem option={{ ...option }} goToAccount={goToAccount} inputValue={inputValue} />
      </li>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams): JSX.Element => {
    const { InputProps, ...restParams } = params;

    return (
      <Box sx={{ position: "relative" }}>
        {searchSuggestion && inputValue.startsWith(searchSuggestion) && (
          <TextField
            value={searchSuggestion}
            disabled
            size="small"
            label=""
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              maxWidth: "32rem"
            }}
            InputLabelProps={{
              sx: {
                color: "secondary.main",
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "secondary.main"
                },
                maxWidth: "32rem"
              }
            }}
            inputProps={{
              sx: {
                padding: "0.1875rem 5rem 0.0625rem 2.75rem",
                maxWidth: "32rem"
              }
            }}
          />
        )}
        <TextField
          {...restParams}
          InputLabelProps={{
            sx: {
              color: "primary.main",
              [`&.${inputLabelClasses.shrink}`]: {
                color: "primary.main"
              }
            }
          }}
          sx={{
            "& .MuiInputBase-input": {
              color: alpha(theme.palette.common.white, 0.96)
            },
            "& .MuiInputBase-placeholder": {
              color: alpha(theme.palette.common.white, 0.96)
            }
          }}
          inputRef={node => {
            searchInputRef.current = node;
          }}
          InputProps={{
            ...InputProps,
            sx: {
              borderRadius: 0,
              backgroundColor: alpha(theme.palette.common.white, 0.1),
              "&:hover": {
                backgroundColor: alpha(theme.palette.common.white, 0.16)
              },
              maxWidth: "32rem"
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: alpha(theme.palette.common.white, 0.3) }} />
              </InputAdornment>
            )
          }}
          placeholder={open ? "Began typing ..." : "Find individual record"}
          size="small"
          onKeyDown={handleKeyDown}
        />
      </Box>
    );
  };

  const onOpen = (): void => {
    document.querySelector(".global-search-hint")?.classList.add("show");
  };

  const onClose = (): void => {
    document.querySelector(".global-search-hint")?.classList.remove("show");
  };

  const handleClose = (): void => {
    if (showDoubleWorkAlert) {
      return;
    }

    setShowDialog(false);
    setCurrentAccount(null);
    document.querySelector(".global-search-hint")?.classList.add("show");
  };

  const buttons: AlertButton[] = [{
    text: "OK",
    onClick: () => setShowDoubleWorkAlert(false)
  }];

  return (
    <div className="global-search-wrapper">
      <Autocomplete
        open={open}
        options={globalSearch}
        filterOptions={opt => opt}
        onFocus={focusOnSearch}
        onBlur={() => setOpen(false)}
        classes={{ noOptions: "global-search-no-options", paper: "global-search-results" }}
        renderInput={params => renderInput(params)}
        getOptionLabel={option => {
          const incidentId = option.data?.incidentId ?? "";
          const patientName = option.data.patientName ?? "";
          return `${incidentId.toString().padStart(10, "0")} - ${patientName}`;
        }}
        componentsProps={{
          paper: {
            sx: {
              width: "56.25rem",
              backgroundColor: "#ffffff",
              paddingLeft: "0rem"
            }
          }
        }}
        renderOption={renderOption}
        inputValue={inputValue}
        onInputChange={onHandleInputChange}
        onOpen={onOpen}
        onClose={onClose}
        freeSolo={false}
        popupIcon=""
        id="global-search-autocomplete"
        ListboxProps={{ style: { maxHeight: "37.5rem", backgroundColor: "#ffffff", paddingTop: 0 } }}
      />
      <div className="global-search-hint">
        <Typography
          variant="body2"
        >
          Start typing Compass ID, patient name, SSN, DOB, phone, claim, bill ID, insurance claim, accident report, invoice, MRN
        </Typography>
      </div>
      <AccountDialog
        open={showDialog}
        close={handleClose}
        id={currentAccount?.data.incidentId}
        dbnum={currentAccount?.data.dbnum ?? ""}
        billId={currentAccount?.data.billId}
        workedOn={analystsWorkingAccount && analystsWorkingAccount?.length > 0}
      />
      <Alert
        show={showDoubleWorkAlert}
        title="Someone else is working this account"
        message="To prevent double touching this account please come back later"
        buttons={buttons}
        handleClose={handleClose}
      />
    </div>
  );
};

export default GlobalSearchDropdown;
