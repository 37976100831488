import { type RuleGroupType, type RuleType } from "react-querybuilder";
import { isDayjs } from "dayjs";
import getQueryBuilderFields, { greaterLessEqOperators } from "../components/query-builder/fields";

const handleNewRule = (rule: RuleType): RuleType => {
  if (rule.operator === "between") {
    const value = String(rule.value);
    return { ...rule, value: `${value},${value}` };
  }

  if (rule.operator === "beginsWith") {
    return { ...rule, value: String(rule.value) };
  }

  return rule;
};

const handleChangeFromBetween = (rule: RuleType, oldRule: RuleType): RuleType => {
  const oldValue = String(oldRule.value);
  return { ...rule, value: oldValue.split(",")[0] };
};

const handleChangeToBetween = (rule: RuleType): RuleType => {
  const value = String(rule.value);
  return { ...rule, value: `${value},${value}` };
};

const handleValueForBetweenOperator = (
  oldFilter: RuleGroupType,
  newFilter: RuleGroupType
): RuleGroupType => {
  const updatedQuery = { ...newFilter };
  const fieldsDetails = getQueryBuilderFields(false);

  updatedQuery.rules = updatedQuery.rules.map(rule => {
    const ruleType = rule as RuleType;
    const oldRule = oldFilter.rules.find(
      x => (x as RuleType).id === ruleType.id
    ) as RuleType | undefined;
    // Add check for futureRange and pastRange

    if ((ruleType.operator === "futureRange" || ruleType.operator === "pastRange") && ruleType.value !== null) {
      const isDate = ruleType.value instanceof Date;
      const isDateFormat = !Number.isInteger(+ruleType.value);
      const isDayjsFormat = isDayjs(ruleType.value);
      if (isDateFormat || isDate || isDayjsFormat) {
        return { ...ruleType, value: null };
      }
    }
    // Check for pastFutureGreaterLessEqOperators, it needs to calculate the field contains
    // futureRage so it doesn't interference with other operators
    // but it should only be applied when the operator is in greaterLessEqOperators

    const operatorFlag = fieldsDetails.find(field => field.name === ruleType.field)?.operators?.some(
      op => "name" in op && op.name === "futureRange"
    ) ?? false;
    const currentOperatorFlag = greaterLessEqOperators.some(op => op.name === ruleType.operator);
    if (operatorFlag && currentOperatorFlag && ruleType.value !== null) {
      const isDate = ruleType.value instanceof Date;
      if (!isDayjs(ruleType.value) && !isDate) {
        return { ...ruleType, value: null };
      }
    }

    if (!oldRule) {
      return handleNewRule(ruleType);
    }

    if (oldRule.operator === "between" && ruleType.operator !== "between") {
      return handleChangeFromBetween(ruleType, oldRule);
    }

    if (ruleType.operator === "between" && oldRule.operator !== "between") {
      return handleChangeToBetween(ruleType);
    }

    return ruleType;
  });

  return updatedQuery;
};

export default handleValueForBetweenOperator;
