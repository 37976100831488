import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import type React from "react";
import { StrictMode, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import defaultTheme from "@themes/default.theme";
import { userAgentApplication, graphScopes } from "@utils/msal.util";
import initDataDog from "@utils/datadog.util";
import initAxe from "@utils/axe.util";
import NetworkOutage from "@components/network-outage/network-outage.component";
import Router from "./Router";
import "./sass/styles.scss";

initDataDog();

const initializeApp = async (): Promise<void> => {
  initAxe(root, 1000); // Assuming initAxe returns a promise or executes synchronously
  await userAgentApplication.initialize(); // Initialize MSAL

  try {
    await userAgentApplication.ssoSilent({
      scopes: graphScopes,
      authority: import.meta.env.VITE_CLIENT_AUTHORITY }); // Attempt SSO
  } catch (error) {
    console.error("SSO Silent Error:", error);
  }
};

const AppInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    void initializeApp().then(() => {
      setInitialized(true);
    });
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return initialized ? <>{children}</> : null;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: Number(import.meta.env.VITE_QUERY_RETRY)
    }
  }
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  <StrictMode>
    <AppInitializer>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={queryClient}>
            <MsalProvider instance={userAgentApplication}>
              <CssBaseline />
              <NetworkOutage />
              <Router />
            </MsalProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </AppInitializer>
  </StrictMode>
);
