import { useState, type MouseEvent } from "react";
import { Paper, Popover, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { type Sort } from "@models/sort.model";
import { type SortOptionType } from "@models/sort-option-type.model";
import type SortType from "@enums/sort-type.enum";
import SortRadioForm from "./sort-radio-form/sort-radio-form.component";
import sortOptions from "./sort-options";

const BlueSortPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  borderWidth: "0",
  display: "flex",
  alignItems: "center",
  margin: "0.5rem 0",
  flexDirection: "column"
}));

interface SortViewProps {
  sortValue: Sort;
  handleChangeSort: (sortColumn: SortType, isDescendent: boolean) => void;
}

const ViewSort = ({ sortValue, handleChangeSort }: SortViewProps): JSX.Element => {
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);

  const handleShowSortOptions = (event: MouseEvent<HTMLElement>): void => {
    setSortAnchorEl(sortAnchorEl ? null : event.currentTarget);
  };

  const handleCloseSortOptions = (): void => {
    setSortAnchorEl(null);
  };

  const getSortValue = (sortOptionType: SortOptionType): string | null => {
    if (sortValue?.sortColumn !== sortOptionType.name) {
      return null;
    }

    return sortValue?.isDescendent ? sortOptionType.descOption : sortOptionType.ascOption;
  };

  return (
    <>
      <IconButton sx={{ margin: "0 1rem" }} size="large" aria-label="sort" color="inherit" onClick={handleShowSortOptions}>
        <FilterAltIcon />
      </IconButton>

      <Popover
        data-testid="sort-options"
        open={Boolean(sortAnchorEl)}
        anchorEl={sortAnchorEl}
        onClose={handleCloseSortOptions}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <BlueSortPaper
          elevation={0}
        >
          <SortRadioForm
            label={sortOptions.assignedTo.label}
            name={sortOptions.assignedTo.name}
            ascOption={sortOptions.assignedTo.ascOption}
            descOption={sortOptions.assignedTo.descOption}
            value={getSortValue(sortOptions.assignedTo)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.admissionDate.label}
            name={sortOptions.admissionDate.name}
            ascOption={sortOptions.admissionDate.ascOption}
            descOption={sortOptions.admissionDate.descOption}
            value={getSortValue(sortOptions.admissionDate)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.balance.label}
            name={sortOptions.balance.name}
            ascOption={sortOptions.balance.ascOption}
            descOption={sortOptions.balance.descOption}
            value={getSortValue(sortOptions.balance)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.dsw.label}
            name={sortOptions.dsw.name}
            ascOption={sortOptions.dsw.ascOption}
            descOption={sortOptions.dsw.descOption}
            value={getSortValue(sortOptions.dsw)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.followupDate.label}
            name={sortOptions.followupDate.name}
            ascOption={sortOptions.followupDate.ascOption}
            descOption={sortOptions.followupDate.descOption}
            value={getSortValue(sortOptions.followupDate)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.timelyFiling.label}
            name={sortOptions.timelyFiling.name}
            ascOption={sortOptions.timelyFiling.ascOption}
            descOption={sortOptions.timelyFiling.descOption}
            value={getSortValue(sortOptions.timelyFiling)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.totalCharge.label}
            name={sortOptions.totalCharge.name}
            ascOption={sortOptions.totalCharge.ascOption}
            descOption={sortOptions.totalCharge.descOption}
            value={getSortValue(sortOptions.totalCharge)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.dis.label}
            name={sortOptions.dis.name}
            ascOption={sortOptions.dis.ascOption}
            descOption={sortOptions.dis.descOption}
            value={getSortValue(sortOptions.dis)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.primaryHealthPolicyNumber.label}
            name={sortOptions.primaryHealthPolicyNumber.name}
            ascOption={sortOptions.primaryHealthPolicyNumber.ascOption}
            descOption={sortOptions.primaryHealthPolicyNumber.descOption}
            value={getSortValue(sortOptions.primaryHealthPolicyNumber)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.primaryPayer.label}
            name={sortOptions.primaryPayer.name}
            ascOption={sortOptions.primaryPayer.ascOption}
            descOption={sortOptions.primaryPayer.descOption}
            value={getSortValue(sortOptions.primaryPayer)}
            handleChangeSort={handleChangeSort}
          />
          <SortRadioForm
            label={sortOptions.upcomingBillDeadlineDate.label}
            name={sortOptions.upcomingBillDeadlineDate.name}
            ascOption={sortOptions.upcomingBillDeadlineDate.ascOption}
            descOption={sortOptions.upcomingBillDeadlineDate.descOption}
            value={getSortValue(sortOptions.upcomingBillDeadlineDate)}
            handleChangeSort={handleChangeSort}
          />
        </BlueSortPaper>
      </Popover>
    </>
  );
};

export default ViewSort;
