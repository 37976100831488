enum SortType {
  AssignedTo = "AssignedTo",
  AdmissionDate = "AdmissionDate",
  Balance = "Balance",
  Dsw = "Dsw",
  FollowupDate = "FollowupDate",
  TimelyFiling = "TimelyFiling",
  TotalCharge = "TotalCharge",
  Dis = "Dis",
  PrimaryHealthPolicyNumber = "PrimaryHealthPolicyNumber",
  PrimaryPayer = "PrimaryPayer",
  UpcomingBillDeadlineDate = "UpcomingBillDeadlineDate"
}

export default SortType;
